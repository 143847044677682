<template>
  <div class="about">
    <img v-if="flag" src="@/static/images/web.jpeg" alt="">
    <img v-else src="@/static/images/phone.jpeg" alt="">
  </div>
</template>
<script>
	import common from '@/utils/common'
export default {
  name: "contactNumber",
  data() {
    return {
      flag: undefined,
    }
  },
  created() {
    this.isPC();
  },
  mounted() {
    this.selfAdaption();
  },

  methods: {
    selfAdaption: function selfAdaption() {
      let _this = this;
      setTimeout(function () {
        window.onresize = () => {
          return (() => {
            _this.$nextTick(() => {
              _this.isPC();
            });
          })();
        };
      }, 200);
    },
    isPC() {

      this.flag = window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      var isPad = common.isPad();
      if(isPad){
				this.flag = false
			}
      // this.flag = !this.flag;

      // if (this.flag) {
      //   // console.log(this.flag);
      //   = false;
      //   // console.log(this.flag)
      // }else{
      //   // console.log(this.flag);
      //   this.flag = true;
      //   // console.log(this.flag)
      // }

    },
  }
}
</script>
<style lang="less" scoped>
.about {
  > img {
    width: 100%;
    height: auto;
  }
}
</style>
